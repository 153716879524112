export const icons = {
  "day 200": "wi wi-storm-showers",
  "day 201": "wi wi-storm-showers",
  "day 202": "wi wi-storm-showers",
  "day 210": "wi wi-day-thunderstorm",
  "day 211": "wi wi-day-thunderstorm",
  "day 212": "wi wi-thunderstorm",
  "day 221": "wi wi-thunderstorm",
  "day 230": "wi wi-day-storm-showers",
  "day 231": "wi wi-day-storm-showers",
  "day 232": "wi wi-day-storm-showers",
  "night 200": "wi wi-storm-showers",
  "night 201": "wi wi-storm-showers",
  "night 202": "wi wi-storm-showers",
  "night 210": "wi wi-night-thunderstorm",
  "night 211": "wi wi-night-thunderstorm",
  "night 212": "wi wi-thunderstorm",
  "night 221": "wi wi-thunderstorm",
  "night 230": "wi wi-night-storm-showers",
  "night 231": "wi wi-night-storm-showers",
  "night 232": "wi wi-night-storm-showers",

  "day 300": "wi wi-day-showers",
  "day 301": "wi wi-day-showers",
  "day 302": "wi wi-day-showers",
  "day 310": "wi wi-day-showers",
  "day 311": "wi wi-day-showers",
  "day 312": "wi wi-day-showers",
  "day 313": "wi wi-day-showers",
  "day 314": "wi wi-day-showers",
  "day 321": "wi wi-day-showers",
  "night 300": "wi wi-night-showers",
  "night 301": "wi wi-night-showers",
  "night 302": "wi wi-night-showers",
  "night 310": "wi wi-night-showers",
  "night 311": "wi wi-night-showers",
  "night 312": "wi wi-night-showers",
  "night 313": "wi wi-night-showers",
  "night 314": "wi wi-night-showers",
  "night 321": "wi wi-night-showers",

  "day 500": "wi wi-showers",
  "day 501": "wi wi-showers",
  "day 502": "wi wi-showers",
  "day 503": "wi wi-showers",
  "day 504": "wi wi-showers",
  "day 511": "wi wi-showers",
  "day 520": "wi wi-showers",
  "day 521": "wi wi-showers",
  "day 522": "wi wi-showers",
  "day 531": "wi wi-showers",
  "night 500": "wi wi-showers",
  "night 501": "wi wi-showers",
  "night 502": "wi wi-showers",
  "night 503": "wi wi-showers",
  "night 504": "wi wi-showers",
  "night 511": "wi wi-showers",
  "night 520": "wi wi-showers",
  "night 521": "wi wi-showers",
  "night 522": "wi wi-showers",
  "night 531": "wi wi-showers",

  "day 600": "wi wi-day-snow",
  "day 601": "wi wi-day-snow",
  "day 602": "wi wi-day-snow",
  "day 611": "wi wi-sleet",
  "day 612": "wi wi-day-sleet",
  "day S613": "wi wi-day-sleet",
  "day 615": "wi wi-day-rain-mix",
  "day 616": "wi wi-day-rain-mix",
  "day 620": "wi wi-snow",
  "day 621": "wi wi-snow",
  "day 622": "wi wi-snow",
  "night 600": "wi wi-night-snow",
  "night 601": "wi wi-night-snow",
  "night 602": "wi wi-night-snow",
  "night 611": "wi wi-sleet",
  "night 612": "wi wi-night-sleet",
  "night 613": "wi wi-night-sleet",
  "night 615": "wi wi-night-rain-mix",
  "night 616": "wi wi-night-rain-mix",
  "night 620": "wi wi-snow",
  "night 621": "wi wi-snow",
  "night 622": "wi wi-snow",

  "day 701": "wi wi-fog",
  "day 711": "wi wi-smoke",
  "day 721": "wi wi-fog",
  "day 731": "wi wi-sandstorm",
  "day 741": "wi wi-fog",
  "day 751": "wi wi-dust",
  "day 761": "wi wi-dust",
  "day 762": "wi wi-dust",
  "day 771": "wi wi-strong-wind",
  "day 781": "wi wi-tornado",
  "night 701": "wi wi-fog",
  "night 711": "wi wi-smoke",
  "night 721": "wi wi-fog",
  "night 731": "wi wi-sandstorm",
  "night 741": "wi wi-fog",
  "night 751": "wi wi-dust",
  "night 761": "wi wi-dust",
  "night 762": "wi wi-dust",
  "night 771": "wi wi-strong-wind",
  "night 781": "wi wi-tornado",

  "day 800": "wi wi-day-sunny",
  "night 800": "wi wi-night-clear",

  "day 801": "wi wi-day-sunny-overcast",
  "day 802": "wi wi-day-cloudy",
  "day 803": "wi wi-day-cloudy-high",
  "day 804": "wi wi-cloudy",
  "night 801": "wi wi-night-partly-cloudy",
  "night 802": "wi wi-night-alt-cloudy",
  "night 803": "wi wi-night-cloudy-high",
  "night 804": "wi wi-cloudy",
};
